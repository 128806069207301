/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';

:root {
    --ion-color-naranja-quemado: #F4A72D;
    --ion-color-naranja-quemado-rgb: 244,167,45;
    --ion-color-naranja-quemado-contrast: #000000;
    --ion-color-naranja-quemado-contrast-rgb: 0,0,0;
    --ion-color-naranja-quemado-shade: #d79328;
    --ion-color-naranja-quemado-tint: #f5b042;
  }
  ion-content{
    --background: #E4C1F9;
  }
  ion-header ion-toolbar {
    --background: var(--ion-color-primary);
    --color: black;
  }

  // ion-menu ion-content {
  //   --background: var(--ion-color-tertiary);
  // }

  // ion-menu ion-content ion-item {
  //   --background: var(--ion-color-tertiary);
  // }

  ion-button {
    --background: var(--ion-color-secondary);
  }

  .ionSelectClass{
    --color:black;
    --background:#FFDDEE;
    ion-label{
      --color:black;
    }
  }
  .snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }
  
  .snow {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    animation: snowfall 5s linear infinite;
  }
  
  @keyframes snowfall {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100vh);
    }
  }
  .modal-fullscreen {
    --width:100% !important;
    --height:100% !important;
}
/* Personalizar el fondo del ion-alert en todos los modos */
ion-alert.custom-ion-alert {
  --background: white !important; /* Reemplaza "white" con el color deseado */
}

/* Personalizar el fondo del ion-alert en modo iOS */
ion-alert.ios.custom-ion-alert {
  --background: white !important; /* Reemplaza "white" con el color deseado */
}

/* Restaurar la línea de división en modo iOS */
ion-alert.ios.custom-ion-alert .alert-button-group {
  border-top: 1px solid #ccc; /* Puedes ajustar el color y el grosor de la línea según tus preferencias */
}
ion-refresher ion-refresher-content {
  background: #ffffff !important; /* Fondo blanco */
  --color: #333 !important; /* Color de texto oscuro */
}
